import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Cookies from 'js-cookie'
import VueNotification from 'vue-notification'
import store from '@/store';
import vm from '@/main';
Vue.use(VueRouter)
Vue.use(VueNotification)

const routes: Array<RouteConfig> = [
    // {
    //     path: '/',
    //     //alias: ['/login'],
    //     name: 'Login',
    //     component: () => import(/* webpackChunkName: "about" */ '@/views/LoginView.vue'),
    //   },
    {
        path: '*',
        redirect: '/',
    },
    {
        path: '/',
        alias: ['/login'],
        name: 'Login',
        component: () => import('@/views/Login.vue'),
    },
    {
        path: '/workspace/course',
        // alias: ['/workSpace'],
        name: 'CourseWorkspace',
        component: () => import('@/views/CourseWorkspace.vue'),
    },
    {
        path: '/workspace/free-course',
        // alias: ['/workSpace'],
        name: 'FreeCourseWorkspace',
        component: () => import('@/views/CourseWorkspace.vue'),
    },
    {
        path: '/workspace/lecture-category',
        // alias: ['/workSpace'],
        name: 'CourseCategoryWorkspace',
        component: () => import('@/views/CategoryWorkspace.vue'),
    },
    {
        path: '/workspace/exam-category',
        // alias: ['/workSpace'],
        name: 'QuizCategoryWorkspace',
        component: () => import('@/views/CategoryWorkspace.vue'),
    },
    {
        path: '/workspace/free-course/edit',
        // alias: ['/workSpace'],
        name: 'EditFreeCourse',
        component: () => import('@/views/EditFreeCourse.vue'),
    },
    {
        path: '/workspace/quiz',
        // alias: ['/workSpace'],
        name: 'QuizWorkspace',
        component: () => import('@/views/QuizWorkspace.vue'),
    },
    {
        path: '/workspace/sellPlan',
        // alias: ['/workSpace'],
        name: 'SellPlanWorkspace',
        component: () => import('@/views/SellPlanWorkspace.vue'),
    },
    {
        path: '/workspace/course/edit',
        // alias: ['/workSpace'],
        name: 'EditCourse',
        component: () => import('@/views/EditCourse.vue'),
    },
    {
        path: '/workspace/quiz/edit',
        // alias: ['/workSpace'],
        name: 'EditQuiz',
        component: () => import('@/views/EditQuiz.vue'),
    },
    {
        path: '/workspace/sellPlan/edit',
        // alias: ['/workSpace'],
        name: 'EditSellPlan',
        component: () => import('@/views/EditSellPlan.vue'),
    },
    {
      path: '/workspace/course/create',
      // alias: ['/workSpace'],
      name: 'CreateCourse',
      component: () => import('@/views/EditCourse.vue'),
    },
    {
      path: '/workspace/quiz/create',
      // alias: ['/workSpace'],
      name: 'CreateQuiz',
      component: () => import('@/views/EditQuiz.vue'),
    },
    {
      path: '/workspace/sellPlan/create',
      // alias: ['/workSpace'],
      name: 'CreateSellPlan',
      component: () => import('@/views/EditSellPlan.vue'),
    },

]


let originpush = VueRouter.prototype.push
// @ts-ignore
VueRouter.prototype.push = function(location,resolve,reject){
    if(resolve && reject){
        originpush.call(this,location,resolve,reject)  // 如果传递了 resolve && reject 调用原来的 push 方法
    }
    else{
        originpush.call(this,location,()=>{},()=>{})
    }
}

// replace 重写
let originreplace = VueRouter.prototype.replace
// @ts-ignore
VueRouter.prototype.replace = function(location,resolve,reject){
    if(resolve && reject){
        originreplace.call(this,location,resolve,reject)
    }else{
        originreplace.call(this,location,()=>{},()=>{})
    }
}

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
let checkAuthTimer = false;
// Check if accessToken still exists every ten minutes
const checkInterval = 10 * 60 * 1000;

// Automatically help users log out and remind them to log in again after they lose their cookies
function checkAuthStatus() {
    const accessToken = Cookies.get('accessToken');
  // Get cookie expires
  // let jwt;
  // if (accessToken) {
  //    jwt = jwt_decode(accessToken);
  // }
  // console.log(accessToken,jwt)

    if(accessToken){
        checkAuthTimer=true;
    }
    else{
        if(checkAuthTimer || vm.$route==null){
            // Log out
            //   store.commit('cleanProfile');
            // vm.$notify({
            //   type: 'info',
            //   title: '請重新登入',
            //   text: '您的驗證已過期，請重新登入',
            // });
            alert("您已登出，請重新登入，謝謝！")
            router.push('/login');
            checkAuthTimer=false;
            return;
        }
    }
}

// Set timer
setInterval(checkAuthStatus, checkInterval);


export default router