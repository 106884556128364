import axios from 'axios';
import vm from '@/main';

const api = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: {
            toString() {
                return vm.$cookies.get('token');
            },
        },
    },
});

api.interceptors.response.use((response) => response, (error) => {
    if (error.message === 'Network Error') {
        vm.$notify({
            type: 'error',
            title: '無法連線到伺服器',
            message: '請確認您的網路狀況是否正常',
        });
        // Auth.logout();
        return Promise.reject(error);
    }

    const path = '';

    switch (error.response.status) {
        case 401:
            const errorData = error.response?.data;
            if (vm.$cookies.isKey('token')) {
                // get 401 because token is expired
                vm.$notify({
                    type: 'info',
                    title: '請重新登入',
                    message: '您的驗證已過期，請重新登入',
                });
            } else {
                // get 401 because login failed
                vm.$notify({
                    type: 'info',
                    title: '登入失敗',
                    message: '錯誤的帳號或密碼',
                });
            }
            
            if (errorData && errorData.description === 'Unsupported media type'){
                vm.$notify({
                    type: 'error',
                    title: '上傳音檔圖檔至S3失敗',
                    message: 'csv檔案含非音檔圖檔的本地檔案路徑',
                });
            }
            else{
                if (vm.$cookies.isKey('token')) {
                    vm.$cookies.remove('token');
                }
                vm.$store.commit('resetState');
                vm.$router.push('/login');
            }
            break;
        case 403:
            // path = '/403';
            vm.$notify({
                type: 'error',
                title: '錯誤',
                message: '您沒有此資源的存取權限',
            });
            break;
        case 409:
            /*vm.$notify({
                type: 'error',
                title: '錯誤',
                message: '重複課名',
            });*/
            break;
        default:
            vm.$notify({
                type: 'error',
                title: '系統錯誤',
                message: '系統錯誤',
            });
            break;
    }
    if (path !== '' && path !== vm.$router.currentRoute.path) {
        vm.$router.push(path);
    }
    return Promise.reject(error);
});

export default api;
