import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { Location } from 'vue-router';
import { courseModel, quizModel, sellPlanModel} from "@/models/WorkspaceModel";

import { ProfileModel } from '@/models/AccountModel';

Vue.use(Vuex);

const getDefaultState = () => ({
    isLoading: false,
    profile: null as ProfileModel | null,
    currentCourseWorkspace: null as courseModel | null,
    currentQuizWorkspace: null as quizModel | null,
    currentSellPlanWorkspace: null as sellPlanModel | null,
    currentFreeCourse: [] as courseModel[] | null,
})

export default new Vuex.Store({
    state: getDefaultState(),
    mutations: {
        updateLoading(state, status) {
            state.isLoading = status;
        },
        resetState(state) {
            Object.assign(state, getDefaultState());
        },
        saveProfile(state, profile: ProfileModel) {
            state.profile = profile;
        },
        changeCourseWorkspace(state, workspace: courseModel) {
            state.currentCourseWorkspace = workspace;
        },
        changeQuizWorkspace(state, workspace: quizModel) {
            state.currentQuizWorkspace = workspace;
        },
        changeSellPlanWorkspace(state, workspace: sellPlanModel) {
            state.currentSellPlanWorkspace = workspace;
        },
        changeFreeCourse(state, freeCourses: courseModel[]) {
            state.currentFreeCourse = freeCourses;
        },
    },
    plugins: [new VuexPersistence().plugin],
});