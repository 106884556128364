

import navbar from '@/components/navbar.vue';

// import FeatureFlagApi from '@/apis/FeatureFlagApi';

export default {
    name: 'App',
    components: {
        navbar,
    },
    computed: {
      // debugMode() {
      //   return this.$store.state.debugMode;
      // },
    },
    async mounted() {
      // fetch all feature flags into store
      // expected to load feature flags once profile is ready
      // try {
      //   if (this.$store.state.profile) {
      //     const featureFlags = await FeatureFlagApi.fetchAllFeatureFlags(this.$store.state.profile.username);
      //     this.$store.commit('updateFeatureFlags', featureFlags);
      //   }
      // } catch (error) {
      //   console.error(error);
      // }
    },
};
