import '@babel/polyfill';
import 'mutationobserver-shim';
import TextareaAutosize from 'vue-textarea-autosize';
import Vue from 'vue';
// import './plugins/bootstrap-vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueCookies from 'vue-cookies';
import VueClipboard from 'vue-clipboard2';
import router from './router'
import store from './store';
import vmodal from 'vue-js-modal';
import VCalendar from 'v-calendar';
import VueNumberInput from '@chenfengyuan/vue-number-input';
import Notifications from 'vue-notification';
import VueSlider from 'vue-slider-component';
import ToggleButton from 'vue-js-toggle-button';
import 'vue-slider-component/theme/default.css';
import App from './App.vue';
import 'blueimp-canvas-to-blob';

Vue.use(BootstrapVue);
Vue.use(ToggleButton);

Vue.use(VueCookies);
Vue.$cookies.config('7d');

Vue.use(VueClipboard);
Vue.use(TextareaAutosize);
Vue.use(vmodal, { dialog: true });

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.component('Loading', Loading);
Vue.component('VueSlider', VueSlider);
Vue.component('VueNumberInput', VueNumberInput);

// Vue.use(Notifications);
Vue.use(ElementUI);

Vue.use(VCalendar, {
    componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
});

// TODO: this is temp fix, please refactor later
store.commit('updateLoading', false);

/*
A Hack makes TextArea.vue work:
prevent insering <div> in the contenteditable div while pressing 'enter' key,
instead, insert a <br>

Reference: https://stackoverflow.com/a/61237402/5731332
*/
document.addEventListener('keydown', (event) => {
    if (event.key === 'Enter') {
        document.execCommand('insertLineBreak');
        event.preventDefault();
    }
});

export default new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
